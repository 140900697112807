import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import AuthGuard from './components/AuthGuard'
import BlogLayout from './components/blog/BlogLayout'
import BrowseLayout from './components/BrowseLayout'
import DashboardLayout from './components/dashboard/DashboardLayout'
import DocsLayout from './components/docs/DocsLayout'
import GuestGuard from './components/GuestGuard'
import LoadingScreen from './components/LoadingScreen'
import MainLayout from './components/MainLayout'

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)

// Browse pages

const Browse = Loadable(lazy(() => import('./pages/browse/Browse')))
const BrowseButtons = Loadable(
  lazy(() => import('./pages/browse/BrowseButtons')),
)
const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')))
const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')))
const BrowseDetailLists = Loadable(
  lazy(() => import('./pages/browse/BrowseDetailLists')),
)
const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')))
const BrowseGridLists = Loadable(
  lazy(() => import('./pages/browse/BrowseGridLists')),
)
const BrowseGroupedLists = Loadable(
  lazy(() => import('./pages/browse/BrowseGroupedLists')),
)
const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')))
const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')))
const BrowseQuickStats = Loadable(
  lazy(() => import('./pages/browse/BrowseQuickStats')),
)
const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')))
const BrowseTypography = Loadable(
  lazy(() => import('./pages/browse/BrowseTypography')),
)

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')))
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery')),
)
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset')),
)
const Register = Loadable(lazy(() => import('./pages/authentication/Register')))
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode')),
)

// Blog pages

const BlogPostCreate = Loadable(
  lazy(() => import('./pages/blog/BlogPostCreate')),
)
const BlogPostDetails = Loadable(
  lazy(() => import('./pages/blog/BlogPostDetails')),
)
const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')))

// Dashboard pages

const Followers = Loadable(lazy(() => import('./pages/dashboard/Followers')))
const LineChart = Loadable(lazy(() => import('./pages/dashboard/LineChart')))

const Strategy1 = Loadable(
  lazy(() => import('./pages/dashboard/strategy/Strategy1')),
)
const Strategy2 = Loadable(
  lazy(() => import('./pages/dashboard/strategy/Strategy2')),
)
const Strategy3 = Loadable(
  lazy(() => import('./pages/dashboard/strategy/Strategy3')),
)
const Strategy4 = Loadable(
  lazy(() => import('./pages/dashboard/strategy/Strategy4')),
)
const Strategy5 = Loadable(
  lazy(() => import('./pages/dashboard/strategy/Strategy5')),
)

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')))
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')))
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')))
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')))
const CustomerDetails = Loadable(
  lazy(() => import('./pages/dashboard/CustomerDetails')),
)
const CustomerEdit = Loadable(
  lazy(() => import('./pages/dashboard/CustomerEdit')),
)
const CustomerList = Loadable(
  lazy(() => import('./pages/dashboard/CustomerList')),
)
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')))
const InvoiceDetails = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceDetails')),
)
const InvoiceList = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceList')),
)
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')))
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')))
const OrderDetails = Loadable(
  lazy(() => import('./pages/dashboard/OrderDetails')),
)
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')))
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')))
const ProductCreate = Loadable(
  lazy(() => import('./pages/dashboard/ProductCreate')),
)
const ProductList = Loadable(
  lazy(() => import('./pages/dashboard/ProductList')),
)

// Moivre Analysis Pages
const DaoStandard = Loadable(
  lazy(() => import('./pages/dashboard/analysis/DaoStandard')),
)
const NftStandard = Loadable(
  lazy(() => import('./pages/dashboard/analysis/NftStandard')),
)
const DefiStandard = Loadable(
  lazy(() => import('./pages/dashboard/analysis/DefiStandard')),
)
const OlympusStandard = Loadable(
  lazy(() => import('./pages/dashboard/analysis/OlympusForks')),
)
const StrongStandard = Loadable(
  lazy(() => import('./pages/dashboard/analysis/StrongForks')),
)
const CurveProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/CurveProjects')),
)
const AvalancheProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/AvalancheProjects')),
)
const PolygonProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/PolygonProjects')),
)
const FantomProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/FantomProjects')),
)
const BscProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/BscProjects')),
)
const MemeProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/MemeProjects')),
)
const SolanaProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/SolanaProjects')),
)
const ZKRollupProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/ZkRollupProjects')),
)
const BobaProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/BobaProjects')),
)
const ArbitrumProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/ArbitrumProjects')),
)
const OptimismProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/OptimismProjects')),
)
const MetisProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/MetisProjects')),
)
const CeloProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/CeloProjects')),
)
const HarmonyProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/HarmonyProjects')),
)
const CardanoProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/CardanoProjects')),
)
const LunaProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/LunaProjects')),
)
const CronosProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/CronosProjects')),
)
const MoonRiverProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/MoonRiverProjects')),
)
const NearProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/NearProjects')),
)
const LiquidityProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/LiquidityProjects')),
)
const AIProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/AIProjects')),
)
const MetaverseProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/MetaverseProjects')),
)
const BridgeProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/BridgeProjects')),
)
const InsuranceProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/InsuranceProjects')),
)
const NFTMarketplaces = Loadable(
  lazy(() => import('./pages/dashboard/analysis/NFTMarketplaces')),
)
const LeverageProjects = Loadable(
  lazy(() => import('./pages/dashboard/analysis/LeverageProjects')),
)

const CustomStrategy = Loadable(
  lazy(() => import('./pages/dashboard/analysis/CustomStrategy')),
)

// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')))

// Success and Cancel Pages
const Success = Loadable(lazy(() => import('./pages/Success')))
const Cancelled = Loadable(lazy(() => import('./pages/Cancelled')))

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired')),
)
const NotFound = Loadable(lazy(() => import('./pages/NotFound')))
const ServerError = Loadable(lazy(() => import('./pages/ServerError')))

// Projects pages

const ProjectBrowse = Loadable(
  lazy(() => import('./pages/dashboard/ProjectBrowse')),
)
const ProjectCreate = Loadable(
  lazy(() => import('./pages/dashboard/ProjectCreate')),
)
const ProjectDetails = Loadable(
  lazy(() => import('./pages/dashboard/ProjectDetails')),
)

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')))
const SocialProfile = Loadable(
  lazy(() => import('./pages/dashboard/SocialProfile')),
)

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')))
const FAQ = Loadable(lazy(() => import('./pages/Faq')))
const Contact = Loadable(lazy(() => import('./pages/Contact')))
const Home = Loadable(lazy(() => import('./pages/Home')))
const Pricing = Loadable(lazy(() => import('./pages/Pricing')))
const TermsOfService = Loadable(
  lazy(() => import('./components/TermsOfService')),
)
const PrivacyPolicy = Loadable(lazy(() => import('./components/PrivacyPolicy')))

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: 'blog',
    element: <BlogLayout />,
    children: [
      {
        path: '/',
        element: <BlogPostList />,
      },
      {
        path: 'new',
        element: <BlogPostCreate />,
      },
      {
        path: ':postId',
        element: <BlogPostDetails />,
      },
    ],
  },
  {
    path: 'contact',
    element: <Contact />,
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />,
      },
      {
        path: 'followers',
        children: [
          {
            path: ':id',
            children: [
              {
                path: ':name',
                element: <Followers />,
              },
            ],
          },
        ],
      },
      {
        path: 'data',
        children: [
          {
            path: ':id',
            children: [
              {
                path: ':name',
                element: <LineChart />,
              },
            ],
          },
        ],
      },
      {
        path: 'strategies',
        children: [
          {
            path: '1',
            element: <Strategy1 />,
          },
          {
            path: '2',
            element: <Strategy2 />,
          },
          {
            path: '3',
            element: <Strategy3 />,
          },
          {
            path: '4',
            element: <Strategy4 />,
          },
          {
            path: '5',
            element: <Strategy5 />,
          },
        ],
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'calendar',
        element: <Calendar />,
      },
      {
        path: 'chat',
        children: [
          {
            path: '/',
            element: <Chat />,
          },
          {
            path: 'new',
            element: <Chat />,
          },
          {
            path: ':threadKey',
            element: <Chat />,
          },
        ],
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: <CustomerList />,
          },
          {
            path: ':customerId',
            element: <CustomerDetails />,
          },
          {
            path: ':customerId/edit',
            element: <CustomerEdit />,
          },
        ],
      },
      {
        path: 'invoices',
        children: [
          {
            path: '/',
            element: <InvoiceList />,
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetails />,
          },
        ],
      },
      {
        path: '/customStrategy',
        element: <CustomStrategy />,
      },
      {
        path: 'analysis',
        children: [
          {
            path: '/defiStandard',
            element: <DefiStandard />,
          },
          {
            path: '/daoStandard',
            element: <DaoStandard />,
          },
          {
            path: '/nftStandard',
            element: <NftStandard />,
          },
          {
            path: '/olympusForks',
            element: <OlympusStandard />,
          },
          {
            path: '/strongForks',
            element: <StrongStandard />,
          },
          {
            path: '/curveProjects',
            element: <CurveProjects />,
          },
          {
            path: '/avalancheProjects',
            element: <AvalancheProjects />,
          },
          {
            path: '/polygonProjects',
            element: <PolygonProjects />,
          },
          {
            path: '/bscProjects',
            element: <BscProjects />,
          },
          {
            path: '/fantomProjects',
            element: <FantomProjects />,
          },
          {
            path: '/memeProjects',
            element: <MemeProjects />,
          },
          {
            path: '/solanaProjects',
            element: <SolanaProjects />,
          },
          {
            path: '/zKRollupProjects',
            element: <ZKRollupProjects />,
          },
          {
            path: '/bobaProjects',
            element: <BobaProjects />,
          },
          {
            path: '/arbitrumProjects',
            element: <ArbitrumProjects />,
          },
          {
            path: '/optimismProjects',
            element: <OptimismProjects />,
          },
          {
            path: '/metisProjects',
            element: <MetisProjects />,
          },
          {
            path: '/celoProjects',
            element: <CeloProjects />,
          },
          {
            path: '/harmonyProjects',
            element: <HarmonyProjects />,
          },
          {
            path: '/cardanoProjects',
            element: <CardanoProjects />,
          },
          {
            path: '/lunaProjects',
            element: <LunaProjects />,
          },
          {
            path: '/cronosProjects',
            element: <CronosProjects />,
          },
          {
            path: '/moonRiverProjects',
            element: <MoonRiverProjects />,
          },
          {
            path: '/nearProjects',
            element: <NearProjects />,
          },
          {
            path: '/liquidityProjects',
            element: <LiquidityProjects />,
          },
          {
            path: '/aiProjects',
            element: <AIProjects />,
          },
          {
            path: '/metaverseProjects',
            element: <MetaverseProjects />,
          },
          {
            path: '/bridgeProjects',
            element: <BridgeProjects />,
          },
          {
            path: '/nftMarketplaces',
            element: <NFTMarketplaces />,
          },
          {
            path: '/insuranceProjects',
            element: <InsuranceProjects />,
          },
          {
            path: '/leveragedProjects',
            element: <LeverageProjects />,
          },
        ],
      },
      {
        path: 'kanban',
        element: <Kanban />,
      },
      {
        path: 'mail',
        children: [
          {
            path: '/',
            element: <Navigate to="/dashboard/mail/all" replace />,
          },
          {
            path: 'label/:customLabel',
            element: <Mail />,
          },
          {
            path: 'label/:customLabel/:emailId',
            element: <Mail />,
          },
          {
            path: ':systemLabel',
            element: <Mail />,
          },
          {
            path: ':systemLabel/:emailId',
            element: <Mail />,
          },
        ],
      },
      {
        path: 'orders',
        children: [
          {
            path: '/',
            element: <OrderList />,
          },
          {
            path: ':orderId',
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: 'finance',
        element: <Finance />,
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <ProductList />,
          },
          {
            path: 'new',
            element: <ProductCreate />,
          },
        ],
      },
      {
        path: 'projects',
        children: [
          {
            path: 'browse',
            element: <ProjectBrowse />,
          },
          {
            path: 'new',
            element: <ProjectCreate />,
          },
          {
            path: ':projectId',
            element: <ProjectDetails />,
          },
        ],
      },
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeed />,
          },
          {
            path: 'profile',
            element: <SocialProfile />,
          },
        ],
      },
    ],
  },
  {
    path: 'docs',
    element: <DocsLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/docs/overview/welcome" replace />,
      },
      {
        path: '*',
        element: <Docs />,
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'browse',
        element: <BrowseLayout />,
        children: [
          {
            path: '/',
            element: <Browse />,
          },
          {
            path: '/buttons',
            element: <BrowseButtons />,
          },
          {
            path: '/inputs',
            element: <BrowseInputs />,
          },
          {
            path: '/charts',
            element: <BrowseCharts />,
          },
          {
            path: '/colors',
            element: <BrowseColors />,
          },
          {
            path: '/data-display/detail-lists',
            element: <BrowseDetailLists />,
          },
          {
            path: '/data-display/quick-stats',
            element: <BrowseQuickStats />,
          },
          {
            path: '/data-display/tables',
            element: <BrowseTables />,
          },
          {
            path: '/forms',
            element: <BrowseForms />,
          },
          {
            path: '/modals',
            element: <BrowseModals />,
          },
          {
            path: '/lists/grouped-lists',
            element: <BrowseGroupedLists />,
          },
          {
            path: '/lists/grid-lists',
            element: <BrowseGridLists />,
          },
          {
            path: '/typography',
            element: <BrowseTypography />,
          },
        ],
      },
      {
        path: 'checkout',
        element: <Checkout />,
      },
      {
        path: 'faq',
        element: <FAQ />,
      },
      {
        path: 'tos',
        element: <TermsOfService />,
      },
      {
        path: 'privacy_policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: 'success',
        element: <Success />,
      },
      {
        path: 'cancelled',
        element: <Cancelled />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]

export default routes
