import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Divider, IconButton, Link, Toolbar } from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import Logo from './Logo';


const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                md: 'inline',
                xs: 'none'
              },
              height: 40,
              width: 40
            }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        >
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/pricing"
            underline="none"
            variant="body1"
          >
            Pricing &nbsp; &nbsp; &nbsp; &nbsp; 
          </Link>
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/faq"
            underline="none"
            variant="body1"
          >
            FAQ &nbsp; &nbsp; &nbsp; &nbsp; 
          </Link>
          <Button
            color="primary"
            component="a"
            href="https://discord.gg/nY8KpZhdRp"
            target="_blank"
            variant="outlined"
            size="small"
          >
            Discord
          </Button>
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2
            }}
          />
          <Button
            color="primary"
            component={RouterLink}
            to="/dashboard"
            size="small"
            variant="contained"
          >
            Enter App
          </Button>
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
