import { useEffect, useCallback, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Typography,
} from '@material-ui/core'
import { moivreApi } from '../../__webApi__/moivreApi'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ReceiptIcon from '@material-ui/icons/Receipt'
import useAuth from '../../hooks/useAuth'
import Adjustments from '../../icons/Adjustments'
import BriefcaseIcon from '../../icons/Briefcase'
import CalendarIcon from '../../icons/Calendar'
import ChartPieIcon from '../../icons/ChartPie'
import ChartSquareBarIcon from '../../icons/ChartSquareBar'
import ClipboardListIcon from '../../icons/ClipboardList'
import FolderOpenIcon from '../../icons/FolderOpen'
import MailIcon from '../../icons/Mail'
import PencilAlt from '../../icons/PencilAlt'
import ShareIcon from '../../icons/Share'
import ShoppingBagIcon from '../../icons/ShoppingBag'
import ShoppingCartIcon from '../../icons/ShoppingCart'
import MoonIcon from '../../icons/Moon'
import StarIcon from '../../icons/Star'
import SunIcon from '../../icons/Sun'
import UserIcon from '../../icons/User'
import UsersIcon from '../../icons/Users'
import Logo from '../Logo'
import NavSection from '../NavSection'
import Scrollbar from '../Scrollbar'
import useMounted from '../../hooks/useMounted'

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props
  const location = useLocation()
  const { user } = useAuth()
  const mounted = useMounted()
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const [users, setUsers] = useState({})

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const getUsers = useCallback(async () => {
    try {
      let response = await moivreApi.getUserDetails(user.id_token)
      setUsers(response.data)
    } catch (err) {
      console.error(err)
    }
  }, [mounted])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const disabled = users.type === 'standard' ? true : false

  const sections = [
    {
      title: 'General',
      items: [
        {
          title: 'Overview',
          path: '/dashboard',
          icon: <ChartPieIcon fontSize="small" />,
        },
        {
          title: 'Account',
          path: '/dashboard/account',
          icon: <UserIcon fontSize="small" />,
        },
      ],
    },
    {
      title: 'Standard Features',
      items: [
        {
          title: 'Defi Protocols',
          path: '/dashboard/analysis/defiStandard',
          icon: <UserIcon fontSize="small" />,
        },
        {
          title: 'Games and NFTs',
          path: '/dashboard/analysis/nftStandard',
          icon: <MoonIcon fontSize="small" />,
        },
        {
          title: 'DAOs',
          icon: <UsersIcon fontSize="small" />,
          path: '/dashboard/analysis/daoStandard',
        },
        {
          title: 'Custom Tags',
          path: '/dashboard/invoices',
          icon: <ReceiptIcon fontSize="small" />,
          children: [
            {
              title: 'Olympus Pro Forks',
              path: '/dashboard/analysis/olympusForks',
            },
            {
              title: 'Curve Flywheel',
              path: '/dashboard/analysis/curveProjects',
            },
            {
              title: 'StrongBlock Fork',
              path: '/dashboard/analysis/strongForks',
            },
            {
              title: 'Meme Coins',
              path: '/dashboard/analysis/memeProjects',
            },
            {
              title: 'Liquidity Optimizers',
              path: '/dashboard/analysis/liquidityProjects',
            },
            {
              title: 'AI related Projects',
              path: '/dashboard/analysis/aiProjects',
            },
            {
              title: 'Metaverse',
              path: '/dashboard/analysis/metaverseProjects',
            },
            {
              title: 'Bridges',
              path: '/dashboard/analysis/bridgeProjects',
            },
            {
              title: 'NFT Marketplaces',
              path: '/dashboard/analysis/nftMarketplaces',
            },
            {
              title: 'Insurance',
              path: '/dashboard/analysis/insuranceProjects',
            },
            {
              title: 'Leveraged Yield',
              path: '/dashboard/analysis/leveragedProjects',
            },
          ],
        },
        {
          title: 'Smart Contract Platforms',
          path: '/dashboard/invoices',
          icon: <SunIcon fontSize="small" />,
          children: [
            {
              title: 'Polygon Projects',
              path: '/dashboard/analysis/polygonProjects',
            },
            {
              title: 'Fantom Projects',
              path: '/dashboard/analysis/fantomProjects',
            },
            {
              title: 'Avalanche Projects',
              path: '/dashboard/analysis/avalancheProjects',
            },
            {
              title: 'Binance Smart Chain Projects',
              path: '/dashboard/analysis/bscProjects',
            },
            {
              title: 'Solana Projects',
              path: '/dashboard/analysis/solanaProjects',
            },
            {
              title: 'Celo Projects',
              path: '/dashboard/analysis/celoProjects',
            },
            {
              title: 'Harmony Projects',
              path: '/dashboard/analysis/harmonyProjects',
            },
            {
              title: 'Cardano Projects',
              path: '/dashboard/analysis/cardanoProjects',
            },
            {
              title: 'Luna Projects',
              path: '/dashboard/analysis/lunaProjects',
            },
            {
              title: 'Cronos Projects',
              path: '/dashboard/analysis/cronosProjects',
            },
            {
              title: 'Moonriver Projects',
              path: '/dashboard/analysis/moonRiverProjects',
            },
            {
              title: 'Near Projects',
              path: '/dashboard/analysis/nearProjects',
            },
            {
              title: 'ZK Rollup Projects',
              path: '/dashboard/analysis/zKRollupProjects',
              cs: disabled,
            },
            {
              title: 'Boba Projects',
              path: '/dashboard/analysis/bobaProjects',
              cs: disabled,
            },
            {
              title: 'Arbitrum Projects',
              path: '/dashboard/analysis/arbitrumProjects',
              cs: disabled,
            },
            {
              title: 'Optimism Projects',
              path: '/dashboard/analysis/optimismProjects',
              cs: disabled,
            },
            {
              title: 'Metis Projects',
              path: '/dashboard/analysis/metisProjects',
              cs: disabled,
            },
          ],
        },
      ],
    },
    {
      title: 'Degen Features',
      items: [
        {
          title: 'Custom Strategy',
          path: '/dashboard/customStrategy',
          icon: <PencilAlt fontSize="small" />,
          cs: disabled,
        },
        {
          title: 'Strategies',
          path: '/dashboard/strategies',
          icon: <Adjustments fontSize="small" />,
          children: [
            {
              title: 'Ancient Resurgence',
              path: '/dashboard/strategies/1',
              cs: disabled,
            },
            {
              title: 'Smart Ratio Alpha',
              path: '/dashboard/strategies/2',
              cs: disabled,
            },
            {
              title: 'Just Discovered',
              path: '/dashboard/strategies/3',
              cs: disabled,
            },
            {
              title: 'New Fund Favorites',
              path: '/dashboard/strategies/4',
              cs: disabled,
            },
            {
              title: 'Older Resurgence',
              path: '/dashboard/strategies/5',
              cs: disabled,
            },
          ],
        },
        {
          title: 'Setup Notification (Coming Soon)',
          path: '/browse/forms',
          icon: <CalendarIcon fontSize="small" />,
          cs: true,
        },
      ],
    }
  ]

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {users.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Your plan:{' '}
                <Link color="primary" component={RouterLink} to="/dashboard/account">
                  {users.type}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Button
            color="primary"
            component="a"
            href="https://discord.gg/nY8KpZhdRp"
            target="_blank"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
          >
            Join Our Discord
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default DashboardSidebar
