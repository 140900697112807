import axios from 'axios'

import { dummyData } from '../dummyData'

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL

// just a really old date, to capture all data, no significance
const FROM_DATE = '2020-10-22T00:00:00-05:00'

class MoivreApi {
  async filterBySubCategories(token, sub_category, from_date = FROM_DATE) {
    let params = {}
    let url =
      BACKEND_BASE_URL +
      '/projects?sub_category=' +
      sub_category +
      '&from_date=' +
      from_date
    return axios.get(url, {
      params,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async filterByTags(token, tags, from_date = FROM_DATE) {
    let params = {}
    let url =
      BACKEND_BASE_URL + '/projects?tags=' + tags + '&from_date=' + from_date
    return axios.get(url, {
      params,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async getUserDetails(token) {
    let params = {}
    let url = BACKEND_BASE_URL + 'user/get_data'
    return axios.get(url, {
      params,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async getUserFollowers(id, token) {
    let params = {}
    let url =
      BACKEND_BASE_URL + `//followers?account_id=${id}&type=fund_whisper`
    return axios.get(url, {
      params,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async applyStrategy(token, strategy) {
    const url = `${BACKEND_BASE_URL}apply_strategy?`
    const query = `strategy=${JSON.stringify(strategy)}`

    return axios.get(url + query, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async getUserFollowersStats(id, token) {
    let params = {}
    let prodUrl = 'https://twittercrypto.herokuapp.com/api/v1/'
    //let url = prodUrl + `follower_stats?account_id=${id}`
    let url = BACKEND_BASE_URL + `follower_stats?account_id=${id}`
    return axios.get(url, {
      params,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async getOverviews(token) {
    let params = {}
    let url = BACKEND_BASE_URL + 'overview'
    return axios.get(url, {
      params,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async getStrategyProjects(id, token, type) {
    let params = {}
    let url =
      BACKEND_BASE_URL +
      `suggested_strategy?strategy_type=${id}&project_type=${type}`
    return axios.get(url, {
      params,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }
}

export const moivreApi = new MoivreApi()
